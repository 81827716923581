<template>
  <div class="audio-transcription-game">
    <h2>Audio Transcription Game</h2>
    <div class="mode-selector">
      <label>
        <input type="radio" v-model="gameMode" value="theme" /> Guess the Theme
      </label>
      <label>
        <input type="radio" v-model="gameMode" value="transcription" /> Transcribe the Sentence
      </label>
    </div>
    <div class="word-count-selector">
      <label for="max-words">Max words:</label>
      <input 
        type="number" 
        id="max-words" 
        v-model.number="maxWords" 
        :min="1" 
        :max="100"
      />
      <button @click="resetToDefault">Reset to Default</button>
    </div>
    <div v-if="gameMode === 'transcription'" class="theme-selector">
      <label for="theme-select">Select Theme:</label>
      <select id="theme-select" v-model="selectedTheme">
        <option value="">Random</option>
        <option v-for="theme in defaultThemes" :key="theme" :value="theme">
          {{ theme }}
        </option>
        <option value="custom">Custom</option>
      </select>
      <input 
        v-if="selectedTheme === 'custom'"
        type="text" 
        v-model="customTheme" 
        placeholder="Enter a custom theme"
      />
    </div>
    <div v-if="audioUrl">
      <audio :src="audioUrl" controls></audio>
      <input v-if="gameMode === 'theme'" v-model="userGuess" placeholder="Guess the theme" />
      <textarea v-else v-model="userGuess" placeholder="Transcribe the sentence"></textarea>
      <button @click="submitGuess" :disabled="!userGuess">Submit Guess</button>
    </div>
    <div v-if="evaluation">
      <h3>Evaluation:</h3>
      <p>{{ evaluation }}</p>
    </div>
    <button @click="generateNewQuestion" :disabled="loading">
      {{ audioUrl ? 'New Question' : 'Start Game' }}
    </button>
    
    <button v-if="sentence" @click="toggleTranscription" class="toggle-transcription">
      {{ showTranscription ? 'Hide' : 'Show' }} Transcription
    </button>
    
    <div v-if="showTranscription && sentence" class="transcription">
      <h3>Actual Transcription:</h3>
      <p>{{ sentence }}</p>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'AudioTranscriptionGame',
  data() {
    return {
      audioUrl: '',
      theme: '',
      sentence: '',
      userGuess: '',
      evaluation: '',
      loading: false,
      gameMode: 'theme',
      maxWords: 42,
      defaultMaxWords: {
        theme: 42,
        transcription: 6
      },
      showTranscription: false,
      selectedTheme: '',
      customTheme: '',
      defaultThemes: [
        'Nature',
        'Technology',
        'History',
        'Science',
        'Art',
        'Sports',
        'Food',
        'Travel',
        'Music',
        'Literature'
      ]
    };
  },
  methods: {
    async generateNewQuestion() {
      this.loading = true;
      try {
        const theme = this.selectedTheme === 'custom' ? this.customTheme : this.selectedTheme;
        const response = await axios.get('/api/audio_transcription/generate', {
          params: { 
            mode: this.gameMode,
            max_words: this.maxWords,
            theme: theme
          }
        });
        this.audioUrl = response.data.audio_url;
        this.theme = response.data.theme;
        this.sentence = response.data.sentence;
        this.userGuess = '';
        this.evaluation = '';
        this.showTranscription = false;
      } catch (error) {
        console.error('Error generating new question:', error);
      }
      this.loading = false;
    },
    async submitGuess() {
      try {
        const response = await axios.post('/api/audio_transcription/check', {
          user_guess: this.userGuess,
          actual_theme: this.theme,
          actual_sentence: this.sentence,
          mode: this.gameMode
        });
        this.evaluation = response.data.evaluation;
      } catch (error) {
        console.error('Error submitting guess:', error);
      }
    },
    resetToDefault() {
      this.maxWords = this.defaultMaxWords[this.gameMode];
    },
    toggleTranscription() {
      this.showTranscription = !this.showTranscription;
    }
  },
  watch: {
    gameMode(newMode) {
      this.audioUrl = '';
      this.userGuess = '';
      this.evaluation = '';
      this.maxWords = this.defaultMaxWords[newMode];
      this.showTranscription = false;
      this.selectedTheme = '';
      this.customTheme = '';
    }
  }
};
</script>

<style scoped>
.audio-transcription-game {
  text-align: left;
  color: var(--text-color);
}

h2, h3 {
  margin-bottom: 20px;
  color: var(--text-color);
}

.mode-selector, .word-count-selector {
  margin-bottom: 20px;
}

.mode-selector label, .word-count-selector label {
  margin-right: 20px;
}

input[type="text"], input[type="number"], textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  background-color: var(--background-color);
  color: var(--text-color);
  border: 1px solid var(--border-color);
}

input[type="number"] {
  width: 60px;
}

textarea {
  height: 100px;
}

button {
  background-color: var(--primary-color);
  color: var(--background-color);
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 10px;
}

button:hover {
  opacity: 0.8;
}

button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

audio {
  width: 100%;
  margin-bottom: 20px;
}

.toggle-transcription {
  margin-top: 20px;
}

.transcription {
  margin-top: 20px;
  padding: 10px;
  background-color: var(--secondary-color);
  border-radius: 5px;
}

.theme-selector {
  margin-bottom: 20px;
}

.theme-selector select, .theme-selector input {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  background-color: var(--background-color);
  color: var(--text-color);
  border: 1px solid var(--border-color);
}
</style>
