<!-- src/components/WordOrder.vue -->
<template>
    <div>
      <div class="word-container">
        <div
          v-for="(word, index) in shuffledWords"
          :key="index"
          class="word-item"
          draggable="true"
          @dragstart="dragStart($event, index)"
          @dragover.prevent
          @drop="drop($event, index)"
        >
          {{ word }}
        </div>
      </div>
      <button @click="checkOrder">Check Order</button>
      <button @click="regeneratePhrase">Regenerate Phrase</button>
      <button @click="playAudio">Play Audio</button>
      <p>{{ resultMessage }}</p>
    </div>
  </template>
  
  <script>
  import axios from 'axios'; // Добавьте эту строку в начало секции <script>

  export default {
    name: 'WordOrder',
    props: {
      shuffledWords: {
        type: Array,
        required: true
      },
      originalWords: {
        type: Array,
        required: true
      },
      resultMessage: {
        type: String,
        required: true
      }
    },
    methods: {
      dragStart(event, index) {
        event.dataTransfer.setData('text/plain', index);
      },
      drop(event, index) {
        const draggedIndex = parseInt(event.dataTransfer.getData('text/plain'));
        this.$emit('swap-words', draggedIndex, index);
      },
      checkOrder() {
        this.$emit('check-order');
      },
      regeneratePhrase() {
        this.$emit('regenerate-phrase');
      },
      async playAudio() {
        try {
          const response = await axios.post('/api/text-to-speech', {
            text: this.originalWords.join(' ')
          }, {
            responseType: 'blob'
          });
          
          const audioBlob = new Blob([response.data], { type: 'audio/mpeg' });
          const audioUrl = URL.createObjectURL(audioBlob);
          const audio = new Audio(audioUrl);
          audio.play();
        } catch (error) {
          console.error('Error playing audio:', error);
        }
      }
    }
  };
  </script>
  
  <style scoped>
  .word-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .word-item {
    background-color: var(--secondary-color);
    color: var(--background-color);
    padding: 10px;
    margin: 5px;
    cursor: move;
    user-select: none;
    transition: background-color 0.3s, color 0.3s;
  }
  </style>
