<!-- src/components/MultipleChoiceQuiz.vue -->
<template>
    <div class="quiz-mode" v-if="quizQuestion">
      <div class="settings">
        <label for="level-select">Select Level:</label>
        <select id="level-select" v-model="selectedLevel" @change="resetQuiz">
          <option v-for="level in levels" :key="level" :value="level">
            {{ level }}
          </option>
        </select>

        <label for="theme-select">Select Theme:</label>
        <select id="theme-select" v-model="selectedTheme" @change="resetQuiz">
          <option v-for="theme in themes" :key="theme" :value="theme">
            {{ theme }}
          </option>
        </select>
      </div>
      <div>
        <h2>{{ quizQuestion.description }}</h2>
        <ul>
          <li v-for="(option, index) in quizQuestion.options" :key="index">
            <label>
              <input
                type="radio"
                :value="option"
                v-model="selectedOption"
              />
              {{ option }}
            </label>
          </li>
        </ul>
        <button @click="submitAnswer" :disabled="!selectedOption">
          Submit
        </button>
        <button @click="fetchQuizQuestion">
          Next Quiz
        </button>
        <p v-if="feedbackMessage" :class="{ 'correct': isCorrect, 'incorrect': !isCorrect }">
          {{ feedbackMessage }}
        </p>
      </div>
    </div>
    <div v-else>
      <p>Loading question...</p>
    </div>
  </template>
  
  <script>
  import axios from 'axios';

  export default {
    name: 'MultipleChoiceQuiz',
    data() {
      return {
        quizQuestion: null,
        selectedOption: '',
        feedbackMessage: '',
        isCorrect: false,
        selectedLevel: 'B2',
        selectedTheme: 'General',
        levels: ['A1', 'A2', 'B1', 'B2', 'C1', 'C2'],
        themes: ['General', 'Programming in Python', 'Bank Account', 'Muscles of the Arms', 'Muscles of the Back', 'Muscles of the Legs', 'Push Ups', 'Pasta', 'Bash']
      };
    },
    methods: {
      async fetchQuizQuestion() {
        try {
          const response = await axios.get('/api/multiple_choice', {
            params: {
              level: this.selectedLevel,
              theme: this.selectedTheme
            }
          });
          this.quizQuestion = response.data;
          this.selectedOption = '';
          this.feedbackMessage = '';
          this.isCorrect = false;
        } catch (error) {
          console.error('Error fetching quiz question:', error);
          this.feedbackMessage = 'Error fetching quiz question. Please try again.';
        }
      },
      submitAnswer() {
        if (this.selectedOption === this.quizQuestion.correctAnswer) {
          this.feedbackMessage = 'Correct! Well done!';
          this.isCorrect = true;
        } else {
          this.feedbackMessage = `Incorrect. Try again!`;
          this.isCorrect = false;
        }
      },
      resetQuiz() {
        this.quizQuestion = null;
        this.selectedOption = '';
        this.feedbackMessage = '';
        this.isCorrect = false;
        this.fetchQuizQuestion();
      }
    },
    mounted() {
      this.fetchQuizQuestion();
    }
  };
  </script>
  
  <style scoped>
  .quiz-mode {
    text-align: left;
    color: var(--text-color);
  }
  
  .settings {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
  }
  
  .settings label {
    margin-right: 5px;
  }
  
  .settings select {
    padding: 5px;
    font-size: 14px;
  }
  
  .quiz-mode h2 {
    margin-bottom: 20px;
    color: var(--text-color);
  }
  
  .quiz-mode ul {
    margin-bottom: 20px;
    list-style-type: none;
    padding: 0;
  }
  
  .quiz-mode li {
    margin-bottom: 10px;
  }
  
  .quiz-mode label {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  
  .quiz-mode input[type="radio"] {
    margin-right: 10px;
  }
  
  .quiz-mode button {
    background-color: var(--primary-color);
    color: var(--background-color);
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .quiz-mode button:hover {
    opacity: 0.8;
  }
  
  .quiz-mode button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  .correct {
    color: green;
  }
  
  .incorrect {
    color: red;
  }
  </style>
