<!-- src/components/AnagramGame.vue -->
<template>
  <div class="anagram-mode" v-if="anagramQuestion">
    <div>
      <h2>Collect the word from the letters:</h2>
      <p class="anagram">{{ anagramQuestion.anagram }}</p>
      <div class="settings">
        <label for="level-select">Select Level:</label>
        <select id="level-select" v-model="selectedLevel" @change="fetchAnagramQuestion">
          <option v-for="level in anagramLevels" :key="level" :value="level">
            {{ level }}
          </option>
        </select>

        <label for="max-letters">Max Letters:</label>
        <input
          type="number"
          id="max-letters"
          v-model.number="maxLetters"
          @change="fetchAnagramQuestion"
          min="3"
          max="12"
          placeholder="Enter max letters"
        />
      </div>

      <input
        v-model="anagramAnswer"
        placeholder="Enter the word"
        @keyup.enter="submitAnagramAnswer"
      />
      <button @click="submitAnagramAnswer" :disabled="!anagramAnswer">
        Check
      </button>
      <button @click="playAudio" :disabled="!anagramQuestion.audio_url">
        Play Audio
      </button>
      <p v-if="anagramFeedback">{{ anagramFeedback }}</p>
      <button @click="fetchAnagramQuestion">New Anagram</button>
    </div>
  </div>
  <div v-else>
    <p>Loading anagram...</p>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'AnagramGame',
  data() {
    return {
      anagramQuestion: null,
      anagramAnswer: '',
      anagramFeedback: '',
      anagramLevels: ['A1', 'A2', 'B1', 'B2', 'C1', 'C2'],
      selectedLevel: 'B2',
      maxLetters: 6
    };
  },
  methods: {
    async fetchAnagramQuestion() {
      console.log('Fetching new anagram question');
      try {
        console.log(`Sending request to /api/anagram with params: level=${this.selectedLevel}, max_letters=${this.maxLetters}`);
        const response = await axios.get('/api/anagram', {
          params: {
            level: this.selectedLevel,
            max_letters: this.maxLetters,
          },
        });
        console.log('Received response:', response.data);
        this.anagramQuestion = response.data;
        this.anagramAnswer = '';
        this.anagramFeedback = '';
      } catch (error) {
        console.error('Error fetching anagram:', error);
        this.anagramFeedback = 'Error fetching anagram. Please try again.';
      }
    },
    async submitAnagramAnswer() {
      try {
        const response = await axios.post('/api/anagram/answer', {
          answer: this.anagramAnswer,
          original_word: this.anagramQuestion.original_word,
        });
        this.anagramFeedback = response.data.message;
      } catch (error) {
        console.error('Error submitting anagram answer:', error);
        this.anagramFeedback = 'Error submitting answer. Please try again.';
      }
    },
    async playAudio() {
      if (this.anagramQuestion && this.anagramQuestion.audio_url) {
        try {
          const response = await axios.get(this.anagramQuestion.audio_url, {
            responseType: 'blob'
          });
          const audioBlob = new Blob([response.data], { type: 'audio/mpeg' });
          const audioUrl = URL.createObjectURL(audioBlob);
          const audio = new Audio(audioUrl);
          audio.play();
        } catch (error) {
          console.error('Error playing audio:', error);
          this.anagramFeedback = 'Error playing audio. Please try again.';
        }
      }
    }
  },
  mounted() {
    this.fetchAnagramQuestion();
  }
};
</script>

<style scoped>
.anagram-mode {
  text-align: left;
  color: var(--text-color);
}

.anagram-mode h2 {
  margin-bottom: 20px;
  color: var(--text-color);
}

.anagram {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  color: var(--primary-color);
}

.anagram-mode input {
  margin-bottom: 10px;
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  background-color: var(--background-color);
  color: var(--text-color);
  border: 1px solid var(--border-color);
}

.anagram-mode button {
  background-color: var(--primary-color);
  color: var(--background-color);
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-bottom: 10px;
}

.anagram-mode button:hover {
  opacity: 0.8;
}

.anagram-feedback {
  margin-top: 10px;
  font-weight: bold;
  color: var(--text-color);
}

.anagram-mode .settings {
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
}

.anagram-mode .settings label {
  margin-right: 5px;
  color: var(--text-color);
}

.anagram-mode .settings select,
.anagram-mode .settings input {
  padding: 5px;
  font-size: 14px;
  background-color: var(--background-color);
  color: var(--text-color);
  border: 1px solid var(--border-color);
}
</style>
