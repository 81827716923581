<!-- src/components/ModeSelector.vue -->
<template>
    <div class="mode-selector">
      <label for="mode-select">Select Mode:</label>
      <select id="mode-select" v-model="selectedMode" @change="changeMode">
        <option v-for="mode in availableModes" :key="mode.value" :value="mode.value">
          {{ mode.label }}
        </option>
      </select>
    </div>
</template>

<script>
export default {
  name: 'ModeSelector',
  props: {
    availableModes: {
      type: Array,
      required: true
    },
    currentMode: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      selectedMode: this.currentMode
    };
  },
  methods: {
    changeMode() {
      this.$emit('mode-changed', this.selectedMode);
    }
  }
};
</script>

<style scoped>
.mode-selector {
  margin-bottom: 20px;
}

.mode-selector label {
  margin-right: 10px;
}

.mode-selector select {
  padding: 5px;
  font-size: 16px;
  width: 220px;
}
</style>