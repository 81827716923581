<template>
    <div class="word-comparison-game">
      <div class="settings">
        <label for="level-select">Select Level:</label>
        <select id="level-select" v-model="selectedLevel" @change="resetGame">
          <option v-for="level in levels" :key="level" :value="level">
            {{ level }}
          </option>
        </select>
  
        <label for="theme-select">Select Theme:</label>
        <select id="theme-select" v-model="selectedTheme" @change="resetGame">
          <option v-for="theme in themes" :key="theme" :value="theme">
            {{ theme }}
          </option>
        </select>
      </div>
  
      <div v-if="words.length === 2">
        <h2>Explain the difference between these words:</h2>
        <p class="word-pair">{{ words[0] }} vs {{ words[1] }}</p>
        <textarea v-model="userComparison" placeholder="Enter your comparison here" rows="4"></textarea>
        <button @click="submitComparison" :disabled="!userComparison">Submit Comparison</button>
      </div>
      
      <div v-if="evaluation">
        <h3>Evaluation:</h3>
        <p>Score: {{ evaluation.score }}/100</p>
        <p>Explanation: {{ evaluation.explanation }}</p>
        <h4>Improved Comparison:</h4>
        <p>{{ evaluation.improved_comparison }}</p>
      </div>
      
      <button @click="fetchWords" v-if="words.length !== 2 || evaluation">
        {{ words.length === 2 ? 'Next Pair' : 'Start Game' }}
      </button>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    name: 'WordComparisonGame',
    data() {
      return {
        levels: ['A1', 'A2', 'B1', 'B2', 'C1', 'C2'],
        themes: ['General', 'Programming', 'Sports', 'Food'],
        selectedLevel: 'B2',
        selectedTheme: 'General',
        words: [],
        userComparison: '',
        evaluation: null
      };
    },
    methods: {
      async fetchWords() {
        console.log('Fetching word pair...');
        try {
          const response = await axios.get('/api/word_comparison', {
            params: {
              level: this.selectedLevel,
              theme: this.selectedTheme
            }
          });
          console.log('Received word pair:', response.data);
          this.words = response.data.words;
          this.userComparison = '';
          this.evaluation = null;
        } catch (error) {
          console.error('Error fetching word pair:', error);
        }
      },
      async submitComparison() {
        console.log('Submitting comparison...');
        try {
          const response = await axios.post('/api/word_comparison', {
            words: this.words,
            comparison: this.userComparison,
            theme: this.selectedTheme
          });
          console.log('Received evaluation:', response.data);
          this.evaluation = response.data;
        } catch (error) {
          console.error('Error submitting comparison:', error);
        }
      },
      resetGame() {
        console.log('Resetting game...');
        this.words = [];
        this.userComparison = '';
        this.evaluation = null;
      }
    }
  };
  </script>
  
  <style scoped>
  .word-comparison-game {
    text-align: left;
    color: var(--text-color);
  }
  
  .settings {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
  }
  
  .settings label {
    margin-right: 5px;
  }
  
  .settings select {
    padding: 5px;
    font-size: 14px;
    background-color: var(--background-color);
    color: var(--text-color);
    border: 1px solid var(--border-color);
  }
  
  h2, h3 {
    margin-bottom: 20px;
    color: var(--text-color);
  }
  
  .word-pair {
    font-size: 1.2em;
    font-weight: bold;
    margin-bottom: 15px;
  }
  
  textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    background-color: var(--background-color);
    color: var(--text-color);
    border: 1px solid var(--border-color);
  }
  
  button {
    background-color: var(--primary-color);
    color: var(--background-color);
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  button:hover {
    opacity: 0.8;
  }
  
  button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  </style>