<!-- src/components/Sidebar.vue -->
<template>
    <div class="sidebar">
      <input
        :value="singleWord"
        @input="$emit('update:singleWord', $event.target.value)"
        placeholder="Enter a word to learn"
        @keyup.enter="generatePhrase"
      />
      <select :value="selectedTense" @change="$emit('update:selectedTense', $event.target.value)">
        <option value="">Select tense</option>
        <option value="present">Present</option>
        <option value="past">Past</option>
        <option value="future">Future</option>
      </select>
      <select :value="selectedLevel" @change="$emit('update:selectedLevel', $event.target.value)">
        <option value="">Select level</option>
        <option value="A1">A1</option>
        <option value="A2">A2</option>
        <option value="B1">B1</option>
        <option value="B2">B2</option>
        <option value="C1">C1</option>
        <option value="C2">C2</option>
      </select>
      
      <!-- Новый селект для выбора максимального количества слов -->
      <label for="max-words-select">Max Words:</label>
      <select id="max-words-select" :value="maxWords" @change="$emit('update:maxWords', parseInt($event.target.value))">
        <option v-for="num in [5, 7, 10, 12, 15]" :key="num" :value="num">
          {{ num }}
        </option>
      </select>
      
      <button @click="generatePhrase" :disabled="!singleWord || !selectedTense || !selectedLevel">
        Generate Phrase
      </button>
    </div>
</template>

<script>
export default {
  name: 'SidebarComponent',
  props: {
    singleWord: {
      type: String,
      required: true
    },
    selectedTense: {
      type: String,
      required: true
    },
    selectedLevel: {
      type: String,
      required: true
    },
    maxWords: {
      type: Number,
      required: true
    }
  },
  methods: {
    generatePhrase() {
      this.$emit('generate-phrase', this.singleWord, this.selectedTense, this.selectedLevel, this.maxWords);
    }
  }
};
</script>

<style scoped>
.sidebar {
  flex: 1;
  padding: 20px;
  background-color: var(--secondary-color);
  color: var(--background-color);
}

input,
select,
button {
  margin-bottom: 10px;
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

input,
select {
  background-color: var(--background-color);
  color: var(--text-color);
  border: 1px solid var(--border-color);
}

button {
  background-color: var(--primary-color);
  color: var(--background-color);
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  opacity: 0.8;
}

button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

label {
  display: block;
  margin-top: 10px;
  margin-bottom: 5px;
  color: var(--background-color);
}
</style>
