<template>
  <div class="true-false-quiz">
    <div class="area-selector">
      <label for="area-select">Select Area:</label>
      <select id="area-select" v-model="selectedArea" @change="fetchQuestion">
        <option value="">Random</option>
        <option v-for="area in areas" :key="area" :value="area">
          {{ area }}
        </option>
      </select>
    </div>
    <div v-if="question">
      <h2>{{ question.statement }}</h2>
      <div class="options">
        <button @click="submitAnswer(true)">True</button>
        <button @click="submitAnswer(false)">False</button>
      </div>
      <p v-if="feedbackMessage">{{ feedbackMessage }}</p>
      <button @click="fetchQuestion">New Statement</button>
    </div>
    <div v-else>
      <p>Click "New Statement" to start</p>
      <button @click="fetchQuestion">New Statement</button>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'TrueFalseQuiz',
  data() {
    return {
      question: null,
      feedbackMessage: '',
      selectedArea: '',
      areas: ['Real Estate', 'Currency Exchange', 'Programming in Python', 'Computer Science', 'History', 'Muscles of The Back', 'Muscles of The Arms', 'Muscles of The Legs']
    };
  },
  methods: {
    async fetchQuestion() {
      try {
        const response = await axios.get('/api/true_false', {
          params: { area: this.selectedArea }
        });
        this.question = response.data;
        this.feedbackMessage = '';
      } catch (error) {
        console.error('Error fetching true/false question:', error);
        this.feedbackMessage = 'Error fetching statement. Please try again.';
      }
    },
    submitAnswer(userAnswer) {
        if (userAnswer === this.question.isTrue) {
            this.feedbackMessage = 'Correct!';
        } else {
            this.feedbackMessage = 'Incorrect. Try again!';
        }
    }
  },
  mounted() {
    this.fetchQuestion();
  }
};
</script>

<style scoped>
.true-false-quiz {
  text-align: center;
  color: var(--text-color);
}

.area-selector {
  margin-bottom: 20px;
}

.area-selector label {
  margin-right: 10px;
  color: var(--text-color);
}

.area-selector select {
  padding: 5px;
  font-size: 14px;
  background-color: var(--background-color);
  color: var(--text-color);
  border: 1px solid var(--border-color);
}

.true-false-quiz h2 {
  margin-bottom: 20px;
  color: var(--text-color);
}

.options {
  margin: 20px 0;
}

.options button {
  margin: 0 10px;
  padding: 10px 20px;
  font-size: 16px;
  background-color: var(--primary-color);
  color: var(--background-color);
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

.options button:hover {
  opacity: 0.8;
}

button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

p {
  margin-top: 20px;
  font-weight: bold;
  color: var(--text-color);
}
</style>