<!-- src/App.vue -->
<template>
  <div id="app">
    <h1>Word Mastery</h1>
    <button @click="toggleTheme">Toggle Theme</button>
    <ModeSelector
      :availableModes="availableModes"
      :currentMode="currentMode"
      @mode-changed="handleModeChange"
    />
    <div class="content">
      <div class="main-area">
        <WordOrder
          v-if="currentMode === 'wordOrder'"
          :shuffledWords="shuffledWords"
          :originalWords="originalWords"
          :resultMessage="resultMessage"
          @swap-words="swapWords"
          @check-order="checkOrder"
          @regenerate-phrase="regeneratePhrase"
        />
        <MultipleChoiceQuiz
          v-else-if="currentMode === 'multipleChoice'"
          :quizQuestion="quizQuestion"
          :feedbackMessage="feedbackMessage"
          @submit-answer="submitAnswer"
        />
        <AnagramGame
          v-else-if="currentMode === 'anagram'"
          :anagramQuestion="anagramQuestion"
          :anagramFeedback="anagramFeedback"
          :anagramLevels="anagramLevels"
          :selectedLevel="selectedLevel"
          :maxLetters="maxLetters"
          @update:selectedLevel="selectedLevel = $event"
          @update:maxLetters="maxLetters = $event"
          @submit-anagram-answer="submitAnagramAnswer"
          @fetch-anagram-question="fetchAnagramQuestion"
        />
        <TrueFalseQuiz
          v-else-if="currentMode === 'trueFalse'"
          :anagramLevels="anagramLevels"
          :selectedLevel="selectedLevel"
          :maxLetters="maxLetters"
        />
        <WordDescriptionGame
          v-else-if="currentMode === 'wordDescription'"
          :levels="anagramLevels"
          :selectedLevel="selectedLevel"
          @update:selectedLevel="selectedLevel = $event"
        />
        <WordComparisonGame
          v-else-if="currentMode === 'wordComparison'"
          :levels="anagramLevels"
          :selectedLevel="selectedLevel"
          @update:selectedLevel="selectedLevel = $event"
        />
        <AudioTranscriptionGame
          v-else-if="currentMode === 'audioTranscription'"
        />
      </div>
      <SidebarComponent
        v-if="currentMode === 'wordOrder'"
        :singleWord="singleWord"
        :selectedTense="selectedTense"
        :selectedLevel="selectedLevel"
        :maxWords="maxWords"
        @update:singleWord="singleWord = $event"
        @update:selectedTense="selectedTense = $event"
        @update:selectedLevel="selectedLevel = $event"
        @update:maxWords="maxWords = $event"
        @generate-phrase="generatePhrase"
      />
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import ModeSelector from './components/ModeSelector.vue';
import WordOrder from './components/WordOrder.vue';
import MultipleChoiceQuiz from './components/MultipleChoiceQuiz.vue';
import AnagramGame from './components/AnagramGame.vue';
import SidebarComponent from './components/SidebarComponent.vue';
import TrueFalseQuiz from './components/TrueFalseQuiz.vue';
import WordDescriptionGame from './components/WordDescriptionGame.vue';
import WordComparisonGame from './components/WordComparisonGame.vue';
import AudioTranscriptionGame from './components/AudioTranscriptionGame.vue';

export default {
  name: 'App',
  components: {
    ModeSelector,
    WordOrder,
    MultipleChoiceQuiz,
    AnagramGame,
    SidebarComponent,
    TrueFalseQuiz,
    WordDescriptionGame,
    WordComparisonGame,
    AudioTranscriptionGame
  },
  data() {
    return {
      singleWord: '',
      selectedTense: 'past',       // Дефолтное значение: Past Tense
      selectedLevel: 'C1',         // Дефолтное значение: C1 уровень
      maxWords: 7,                 // Дефолтное значение: 7 слов
      currentPhrase: 'Your phrase will appear here',
      shuffledWords: [],
      originalWords: [],
      resultMessage: '',
      currentMode: 'wordOrder',    // Default mode
      availableModes: [
        { value: 'wordOrder', label: 'Word Order' },
        { value: 'multipleChoice', label: 'Multiple Choice Quiz' },
        { value: 'anagram', label: 'Anagram Games' },
        { value: 'trueFalse', label: 'True or False' },
        { value: 'wordDescription', label: 'Word Description' },
        { value: 'wordComparison', label: 'Word Comparison' },
        { value: 'audioTranscription', label: 'Audio Transcription' }
      ],
      isPhraseGenerated: false,
      quizQuestion: null,
      selectedOption: '',
      feedbackMessage: '',
      // Новые данные для режима анаграмм
      anagramQuestion: null,
      anagramAnswer: '',
      anagramFeedback: '',
      anagramLevels: ['A1', 'A2', 'B1', 'B2', 'C1', 'C2'],
      maxLetters: 6, // По умолчанию
      isDarkTheme: true, // Изменено на true по умолчанию
    };
  },
  watch: {
    singleWord() {
      if (this.isPhraseGenerated) {
        this.isPhraseGenerated = false;
        this.resetPhrase();
      }
    },
    selectedTense() {
      if (this.isPhraseGenerated) {
        this.isPhraseGenerated = false;
        this.resetPhrase();
      }
    },
    currentMode(newMode) {
      if (newMode === 'multipleChoice') {
        this.fetchQuizQuestion();
      } else if (newMode === 'anagram') {
        this.fetchAnagramQuestion();
      } else if (newMode === 'wordOrder') {
        this.selectedTense = 'past';
        this.selectedLevel = 'C1';
        this.maxWords = 7;
        this.resetPhrase();
      } else if (newMode === 'trueFalse') {
        this.resetTrueFalse();
      }
    },
  },
  methods: {
    handleModeChange(newMode) {
      this.currentMode = newMode;
    },
    swapWords(draggedIndex, droppedIndex) {
      const temp = this.shuffledWords[droppedIndex];
      this.shuffledWords[droppedIndex] = this.shuffledWords[draggedIndex];
      this.shuffledWords[draggedIndex] = temp;
    },
    async generatePhrase(word, tense, level, maxWords) {
      if (!word || !tense || !level || !maxWords) {
        this.resultMessage = 'Please enter a word, select a tense, choose a level, and set max words.';
        return;
      }
      try {
        const response = await axios.post('/api/word_order/generate_phrase', {
          word: word,
          tense: tense,
          level: level,
          max_words: maxWords,
        });
        this.currentPhrase = response.data.original_phrase;
        this.shuffledWords = response.data.shuffled_words;
        this.originalWords = response.data.original_phrase.split(' ');
        this.resultMessage = '';
        this.isPhraseGenerated = true;
      } catch (error) {
        console.error('Error generating phrase:', error);
        this.resultMessage = 'Error generating phrase. Please try again.';
      }
    },
    regeneratePhrase() {
      this.generatePhrase(this.singleWord, this.selectedTense, this.selectedLevel, this.maxWords);
    },
    checkOrder() {
      const isCorrect = this.shuffledWords.join(' ').toLowerCase() === this.currentPhrase.toLowerCase();
      this.resultMessage = isCorrect ? 'Correct! Well done!' : 'Not quite right. Try again!';
    },
    resetPhrase() {
      this.currentPhrase = 'Your phrase will appear here';
      this.shuffledWords = [];
      this.originalWords = [];
      this.resultMessage = '';
    },
    async fetchQuizQuestion() {
      try {
        const response = await axios.get('/api/multiple_choice');
        this.quizQuestion = response.data;
        this.selectedOption = '';
        this.feedbackMessage = '';
      } catch (error) {
        console.error('Error fetching quiz question:', error);
        this.feedbackMessage = 'Error fetching quiz question. Please try again.';
      }
    },
    async submitAnswer({ selectedOption, correctAnswer }) {
      if (selectedOption === correctAnswer) {
        this.feedbackMessage = 'Correct! Well done!';
      } else {
        this.feedbackMessage = `Incorrect. The correct answer is: ${correctAnswer}`;
      }
      
      // Fetch a new question after a short delay
      setTimeout(() => {
        this.fetchQuizQuestion();
      }, 2000);
    },
    async fetchAnagramQuestion() {
      try {
        const response = await axios.get('/api/anagram', {
          params: {
            level: this.selectedLevel,
            max_letters: this.maxLetters,
          },
        });
        this.anagramQuestion = response.data;
        this.anagramAnswer = '';
        this.anagramFeedback = '';
      } catch (error) {
        console.error('Error fetching anagram question:', error);
        this.anagramFeedback = 'Error fetching anagram. Please try again.';
      }
    },
    async submitAnagramAnswer(answer) {
      try {
        const response = await axios.post('/api/anagram/answer', {
          id: this.anagramQuestion.id,
          answer: answer,
        });
        this.anagramFeedback = response.data.message;
        if (response.data.message.startsWith('Правильный') || response.data.message.startsWith('Correct')) {
          setTimeout(() => {
            this.fetchAnagramQuestion();
          }, 2000);
        }
      } catch (error) {
        console.error('Error submitting anagram answer:', error);
        this.anagramFeedback = 'Error submitting answer. Please try again.';
      }
    },
    resetTrueFalse() {
      // Логика сброса для режима Правда или Ложь, если необходимо
    },
    toggleTheme() {
      this.isDarkTheme = !this.isDarkTheme;
      localStorage.setItem('isDarkTheme', this.isDarkTheme);
      this.applyTheme();
    },
    applyTheme() {
      document.documentElement.classList.toggle('dark-theme', this.isDarkTheme);
    },
  },
  created() {
    const savedTheme = localStorage.getItem('isDarkTheme');
    this.isDarkTheme = savedTheme !== null ? savedTheme === 'true' : true;
    this.applyTheme();
    if (this.currentMode === 'multipleChoice') {
      this.fetchQuizQuestion();
    } else if (this.currentMode === 'anagram') {
      this.fetchAnagramQuestion();
    } else if (this.currentMode === 'wordOrder') {
      this.selectedTense = 'past';
      this.selectedLevel = 'C1';
      this.maxWords = 7;
    } else if (this.currentMode === 'trueFalse') {
      // Инициализация режима Правда или Ложь при запуске
    }
  },
};
</script>

<style>
@import '@/assets/styles/app.scss';

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  transition: background-color 0.3s, color 0.3s;
}

html {
  background-color: var(--background-color);
  color: var(--text-color);
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.content {
  display: flex;
  justify-content: space-between;
  max-width: 800px;
  margin: 0 auto;
}

.main-area {
  flex: 2;
  padding: 20px;
}

.sidebar {
  flex: 1;
  padding: 20px;
  background-color: var(--secondary-color);
}

button {
  background-color: var(--primary-color);
  color: var(--background-color);
  border: none;
  padding: 10px 20px;
  margin: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  opacity: 0.8;
}

input, select {
  background-color: var(--background-color);
  color: var(--text-color);
  border: 1px solid var(--border-color);
  padding: 5px;
  margin: 5px;
}
</style>