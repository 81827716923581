<template>
  <div class="word-description-game">
    <div class="settings">
      <label for="level-select">Select Level:</label>
      <select id="level-select" v-model="selectedLevel" @change="resetGame">
        <option v-for="level in levels" :key="level" :value="level">
          {{ level }}
        </option>
      </select>

      <label for="theme-select">Select Theme:</label>
      <select id="theme-select" v-model="selectedTheme" @change="resetGame">
        <option v-for="theme in themes" :key="theme" :value="theme">
          {{ theme }}
        </option>
      </select>
    </div>

    <div v-if="currentWord">
      <h2>Describe this word: {{ currentWord }}</h2>
      <textarea v-model="userDescription" placeholder="Enter your description here" rows="4"></textarea>
      <button @click="submitDescription" :disabled="!userDescription">Submit Description</button>
    </div>
    
    <div v-if="evaluation">
      <h3>Evaluation:</h3>
      <p>Score: {{ evaluation.score }}/100</p>
      <p>Explanation: {{ evaluation.explanation }}</p>
      <h4>Improved Description:</h4>
      <p>{{ evaluation.improved_description }}</p>
    </div>
    
    <button @click="fetchWord" v-if="!currentWord || evaluation">
      {{ currentWord ? 'Next Word' : 'Start Game' }}
    </button>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'WordDescriptionGame',
  data() {
    return {
      levels: ['A1', 'A2', 'B1', 'B2', 'C1', 'C2'],
      themes: ['General', 'Programming', 'Sports', 'Food'],
      selectedLevel: 'B2',
      selectedTheme: 'General',
      currentWord: '',
      userDescription: '',
      evaluation: null
    };
  },
  methods: {
    async fetchWord() {
      console.log('Fetching word...');
      try {
        const response = await axios.get('/api/word_description', {
          params: {
            level: this.selectedLevel,
            theme: this.selectedTheme
          }
        });
        console.log('Received word:', response.data);
        this.currentWord = response.data.word;
        this.userDescription = '';
        this.evaluation = null;
      } catch (error) {
        console.error('Error fetching word:', error);
      }
    },
    async submitDescription() {
      console.log('Submitting description...');
      try {
        const response = await axios.post('/api/word_description', {
          word: this.currentWord,
          description: this.userDescription,
          theme: this.selectedTheme
        });
        console.log('Received evaluation:', response.data);
        this.evaluation = response.data;
      } catch (error) {
        console.error('Error submitting description:', error);
      }
    },
    resetGame() {
      console.log('Resetting game...');
      this.currentWord = '';
      this.userDescription = '';
      this.evaluation = null;
    }
  }
};
</script>

<style scoped>
.word-description-game {
  text-align: left;
  color: var(--text-color);
}

.settings {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.settings label {
  margin-right: 5px;
}

.settings select {
  padding: 5px;
  font-size: 14px;
  background-color: var(--background-color);
  color: var(--text-color);
  border: 1px solid var(--border-color);
}

h2, h3 {
  margin-bottom: 20px;
  color: var(--text-color);
}

textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  background-color: var(--background-color);
  color: var(--text-color);
  border: 1px solid var(--border-color);
}

button {
  background-color: var(--primary-color);
  color: var(--background-color);
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  opacity: 0.8;
}

button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
</style>
